import React, { FC } from 'react';

import IconCustom from 'components/common/IconCustom';
import { IContactsList } from './models';
import './ContactsList.scss';

const ContactsList: FC<IContactsList> = ({ data }) => (
  <ul className="dt-contacts__list">
    {data.map(({ title, icon, text }) =>
      title || text ? (
        <li key={title}>
          <div className="dt-contacts__list-title" dangerouslySetInnerHTML={{ __html: title }} />
          <div className="dt-contacts__list-content">
            <IconCustom icon={icon} />
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </li>
      ) : null
    )}
  </ul>
);

export default ContactsList;
